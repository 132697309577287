import * as React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'

import DownloadAction from '../components/DownloadAction'
import PageSuggestionBanner from '../components/PageSuggestionBanner'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
	location: Location
	data: any
}

const AboutExkivity = ({ location, data }: Props) => {
	return (
		<Layout>
			<SEO
				SEOData={{
					title: 'About EXKIVITY® (mobocertinib) | For Patients',
					description:
						'See how EXKIVITY® (mobocertinib) works in treating EGFR Exon20 insertion-positive non-small cell lung cancer (NSCLC) for people who have had chemotherapy containing platinum. See Patient Information, Safety, and Boxed Warning.',
					path: location.pathname,
				}}
			/>
			<Hero bgImage={data.heroImage} imageAlt=''>
				<p className='text-white pt-2 sm:pt-0'>
					Designed Just for Your
					<br className='hidden lg:block' /> Speci
					<span className='tracking-wide'>fi</span>c Mutation
				</p>
			</Hero>
			<div className='grid grid-cols-12 mt-10'>
				<div className='col-start-2 col-span-10'>
					<p className='md:text-2xl text-xl font-bold text-pompadourViolet mb-3'>
						Meet EXKIVITY
					</p>
					<p>
						Now, <strong>for the first time</strong>, there&apos;s an oral therapy
						that targets{' '}
						<strong>epidermal growth factor receptor (EGFR) Exon20 insertion</strong>{' '}
						mutations for people who have been on chemotherapy
						<strong>—EXKIVITY.</strong>
					</p>
					<div className='md:p-10 py-10 px-5 bg-weakYellow mt-10 md:flex w-full lg:w-10/12 mx-auto'>
						<div className='md:pb-0 pb-5 flex md:items-start items-center md:justify-between justify-center md:w-8/10'>
							<StaticImage src='../images/about-exkivity/rx-icon.png' alt='' />
						</div>
						<div className='lg:ml-5'>
							<p className='mt-3'>
								EXKIVITY is a prescription medicine used to treat adults with non-small
								cell lung cancer (NSCLC):
							</p>
							<ul className='list-disc list-outside marker:text-mediumVioletRed mt-6 ml-5'>
								<li>
									that has spread to other parts of the body (metastatic) and cannot be
									removed by surgery, <span className='font-bold'>and</span>
								</li>
								<li>
									has a certain abnormal epidermal growth factor receptor (EGFR) gene,{' '}
									<span className='font-bold'>and</span>
								</li>
								<li>
									whose disease has worsened while on or after chemotherapy that contains
									platinum
								</li>
							</ul>
							<p className='mt-6'>
								Your healthcare provider will perform a test to make sure that EXKIVITY
								is right for you.
							</p>
							<p className='mt-2'>
								It is not known if EXKIVITY is safe and effective in children.
							</p>
							<p className='mt-2'>
								EXKIVITY is approved based on a medical study that measured how many
								people responded to treatment and for how long. There are ongoing
								studies to confirm its continued approval.
							</p>
						</div>
					</div>
					<h1 className='md:text-2xl text-xl font-bold text-pompadourViolet mb-3 mt-10'>
						How EXKIVITY works
					</h1>
					<p>
						EXKIVITY is <strong>the only oral therapy designed</strong> to bind to
						EGFR proteins with <strong>Exon20 insertion mutations.</strong>
					</p>
					<div className='md:grid md:grid-cols-7 mt-10'>
						<div className='md:col-span-3 md:mb-0 mb-10'>
							<p className='md:text-2xl text-xl font-bold text-center mb-5'>
								Without EXKIVITY
							</p>
							<StaticImage
								src='../images/about-exkivity/without-EXKIVITY-image.png'
								alt='An EGFR protein with an Exon20 insertion mutation that may signal tumor cells to grow uncontrollably.'
							/>
						</div>
						<div className='md:col-start-5 md:col-span-3'>
							<p className='md:text-2xl text-xl font-bold text-center mb-5'>
								With EXKIVITY
							</p>
							<StaticImage
								src='../images/about-exkivity/with-EXKIVITY-image.png'
								alt='EXKIVITY binding to a mutated EGFR protein, which may deactivate it.'
							/>
						</div>
					</div>

					<p className='my-10'>
						<strong className='text-mediumVioletRed'>
							EXKIVITY may cause serious side effects,
						</strong>{' '}
						including changes to the electrical activity of your heart, lung problems,
						heart problems, including heart failure, and diarrhea.
					</p>
					<DownloadAction
						text='Download the Patient Brochure'
						fileURL='/brochures/Exkivity Patient Education Brochure.pdf'
						images={{
							desktop:
								data.downloadPatientBrochureDesktop.childImageSharp.gatsbyImageData,
							mobile:
								data.downloadPatientBrochureMobile.childImageSharp.gatsbyImageData,
						}}
					/>
				</div>
			</div>
			<div className='grid grid-cols-12 bg-lightGrey'>
				<div className='col-start-2 col-span-10'>
					<PageSuggestionBanner
						header='EXKIVITY was studied in people like you'
						buttonText='See the study results'
						to='/study-results'
					/>
				</div>
			</div>
		</Layout>
	)
}
export default AboutExkivity

export const query = graphql`
	{
		heroImage: file(
			relativePath: { eq: "about-exkivity/hero-about-exkivity.png" }
		) {
			...heroImage
		}

		downloadPatientBrochureMobile: file(
			relativePath: { eq: "resources-patient-brochure-thumb.png" }
		) {
			...downloadActionImage
		}
		downloadPatientBrochureDesktop: file(
			relativePath: { eq: "patient-brochure-thumb.png" }
		) {
			...downloadActionImage
		}
	}
`
